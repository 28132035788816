import { Currency, ETHER, Token } from '@uniswap/sdk'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import useHttpLocations from '../../hooks/useHttpLocations'
import { useGetTokens, WrappedTokenInfo } from '../../state/lists/hooks'
import Logo from '../Logo'
import EthereumLogo from '../../assets/svg/logoAia.svg'

export const getTokenLogoURL = (address: string) => `/logo/${address}.png`

const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: ${({ size }) => size};
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.075);
  background-color: ${({ theme }) => theme.white};
`
const StyledEthereumLogo = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  border-radius: 24px;
`

const getLogoUrl = (url:string) => {
  if(!url) return url;
  const port = window.location.port
  const origin = port ? process.env.REACT_APP_HTTP_API : `${window.location.origin}/`;
  return url.includes('http') ? `${url}` : `${origin}${url}`
}
export default function CurrencyLogo({
  currency,
  size = '24px',
  style
}: {
  currency?: Currency | null | any
  size?: string
  style?: React.CSSProperties
}) {
  const uriLocations = useHttpLocations(currency instanceof WrappedTokenInfo ? getLogoUrl(currency.logoURI as string) : undefined)
  const srcs: string[] = useMemo(() => {
    if (currency === ETHER) return []
    if (currency instanceof Token) {
      if (currency instanceof WrappedTokenInfo) {
        return [...uriLocations, getTokenLogoURL(currency.address)]
      }
      return [getTokenLogoURL(currency.address)]
    }
    return []
  }, [currency, uriLocations])

  const tokens = useGetTokens()

  const currentTokenLogoURI = useMemo(() => {
    if (tokens.length > 0) {
      if (currency.address) {
        const logoURI = tokens.find(item => item.address.toLowerCase() == currency.address.toLowerCase())?.logoURI ?? ''
        return getLogoUrl(logoURI)
      }
    }
    return ''
  }, [tokens, currency])

  if (currency?.symbol === 'AIA' || currency?.symbol === 'WAIA') {
    return <StyledEthereumLogo src={EthereumLogo} size={size} style={style} />
  }

  if (currentTokenLogoURI) {
    return <StyledEthereumLogo src={currentTokenLogoURI} size={size} style={style} />
  }
  return <StyledLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} />
}
