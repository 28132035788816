/**
 * @name: newHooks
 * @author: mahuanhuan
 * @date: 2024/10/21
 * @description：相关数据处理
 * @update: 2024/10/21
 */
import { useEffect, useMemo, useState } from "react";
import { getSwapPoolList } from "../../services/global";
import { commonFunData, commonTokenFunData, getDataHandle, isError, isLoading } from "./utils";
import { useTranslation } from "react-i18next";
import { StakingInfo } from "./hooks";
import { useActiveWeb3React } from "../../hooks";
import { STAKING_REWARDS_INTERFACE } from "../../constants/abis/staking-rewards";
import { useMultipleContractSingleData } from "../multicall/hooks";
import useCurrentBlockTimestamp from "../../hooks/useCurrentBlockTimestamp";
import { UNI } from "../../constants";

// @ts-ignore
export const useStakingInfoPage = (() => {
  const {i18n} = useTranslation()
  const i18nAny: any = i18n
  const {chainId, account} = useActiveWeb3React();
  const [ isActive, setIsActive ] = useState(true)
  const [ loading, setLoading ] = useState(true)
  const [ getProgress, setProgress ] = useState(0)
  const [ list, setList ] = useState<Record<string, any>[]>([]);
  const currentBlockTimestamp = useCurrentBlockTimestamp();
  const uni = chainId ? UNI[chainId] : undefined

  const language = useMemo(() => {
    let lang = '';
    switch (i18nAny.language) {
      case 'zh':
        lang = 'zh-CN';
        break;
      case 'tw':
        lang = 'zh-TW';
        break;
      case 'en':
        lang = 'en-US';
        break;
      case 'jp':
        lang = 'ja-JP';
        break;
      case 'turkey':
        lang = 'tr-TR';
        break;
    }
    return lang;
  }, [ i18nAny.language ])

  const getPoolList = async (status: number, type: string) => {
    setLoading(true)
    try{
      const list = await getSwapPoolList({status, type});
      // setLoading(false)
      if (list.length > 0) {
        const data = getDataHandle(list)
        setList(data);
      } else {
        setLoading(false)
        setList([])
      }
    } catch (e) {
      setLoading(false)
    }

  }

  useEffect(() => {
    getPoolList(getProgress, language).then()
  }, [ language ]);

  // 进行中/已结束
  const onProgressChange = (num: number) => {
    setProgress(num)
    setIsActive(true)
    getPoolList(num, language).then()
  }

  const stakingRewardAddressAry: string[] = useMemo(() => list.map(({stakingRewardAddress}) => stakingRewardAddress), [ list ]);
  const accountArg = useMemo(() => [ account ?? undefined ], [ account ]);

  const balances = useMultipleContractSingleData(stakingRewardAddressAry, STAKING_REWARDS_INTERFACE, 'balanceOf', accountArg);
  const earnedAmounts = useMultipleContractSingleData(stakingRewardAddressAry, STAKING_REWARDS_INTERFACE, 'earned', accountArg);
  const totalSupplies = useMultipleContractSingleData(stakingRewardAddressAry, STAKING_REWARDS_INTERFACE, 'totalSupply');

  const rewardRates = useMultipleContractSingleData(stakingRewardAddressAry, STAKING_REWARDS_INTERFACE, 'rewardRate', undefined);
  const periodFinishes = useMultipleContractSingleData(stakingRewardAddressAry, STAKING_REWARDS_INTERFACE, 'periodFinish', undefined);

  // 处理合约数据
  const stakingInfoList: StakingInfo[] = useMemo(() => {
    if (!chainId || !uni) {
      setLoading(false)
      return []
    }
    return stakingRewardAddressAry.reduce<StakingInfo[]>((memo, stakingRewardAddress, index) => {
      const status = {
        balanceState: balances[index],
        earnedAmountState: earnedAmounts[index],
        totalSupplyState: totalSupplies[index],
        rewardRateState: rewardRates[index],
        periodFinishState: periodFinishes[index]
      };

      if (isLoading(status)) {
        if (isError(status)) {
          return memo;
        }

        if(list[index].tokens.length> 1) {
          // 双币池
          const datas = commonFunData({...status, info: list[index], uni, currentBlockTimestamp});
          memo.push({stakingRewardAddress: stakingRewardAddress, ...datas});
        } else {
          // 单币池
          const datas = commonTokenFunData({...status, info: list[index], uni, currentBlockTimestamp});
          memo.push({stakingRewardAddress: stakingRewardAddress, ...datas});
        }

      }
      return memo;
    }, []);
  }, [ balances, earnedAmounts, totalSupplies, rewardRates, periodFinishes, list, currentBlockTimestamp ]);

  useEffect(()=>{
    if(list.length> 0 && stakingInfoList.length> 0) {
      setLoading(false)
    }
  }, [list, stakingInfoList])

  useEffect(()=>{
    if(list.length> 0 && account) {
      setLoading(true)
    }
  }, [list, account])

  return {
    list,
    getProgress,
    onProgressChange,
    isActive,
    setIsActive,
    stakingInfoList,
    loading
  }
})