/**
 * @name: global
 * @author: mahuanhuan
 * @date: 2024/10/16
 * @description：
 * @update: 2024/10/16
 */
import request from './request'

// 代币配置列表查询
export async function getAiaSwapCoinConfig() {
  return request('GET', '/aiaSwapCoinConfig/list')
}

// 进行中状态矿池apy查询列表
export async function getAiaSwapCoinApy() {
  return request('GET', '/aiaSwapPoolConfig/apy')
}


// 矿池列表查询
export async function getSwapPoolList(body = {}) {
  return request('POST', '/aiaSwapPoolConfig/list', {
    body
  })
}
