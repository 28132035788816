import { useEffect, useMemo, useState } from 'react'
import { REACT_APP_POOL_URL } from 'utils'
import axios from 'axios'
import { useActiveWeb3React } from 'hooks'
import { BigNumber } from 'ethers'
import { useEarnState } from 'state/earn/hooks'
import { getAiaSwapCoinApy, getSwapPoolList } from "../../services/global";
import { getDataHandle } from "../../state/stake/utils";
axios.defaults.timeout = 1000 * 5
// is Android
export const judgePhoneType = () => {
  let isAndroid = false
  let isIOS = false
  let isIOS9 = false
  let version: any
  const u = navigator.userAgent
  const ua = u.toLowerCase()
  //Android系统
  if (u.indexOf('Android') > -1 || u.indexOf('Linux') > -1) {
    //android终端或者uc浏览器
    isAndroid = true
  }

  //ios
  if (ua.indexOf('like mac os x') > 0) {
    // eslint-disable-next-line @typescript-eslint/camelcase
    const regStr_saf = /os [\d._]*/gi
    const verinfo = ua.match(regStr_saf)
    version = (verinfo + '').replace(/[^0-9|_.]/gi, '').replace(/_/gi, '.')
  }
  // eslint-disable-next-line @typescript-eslint/camelcase
  const version_str = version + ''
  // ios9以上
  // eslint-disable-next-line @typescript-eslint/camelcase
  if (version_str !== 'undefined' && version_str.length > 0) {
    version = parseInt(version)
    if (version >= 8) {
      isIOS9 = true
    } else {
      isIOS = true
    }
  }
  return { isAndroid, isIOS, isIOS9 }
}
// click button to share app
export const handleShareClick = (apy: number, phase: string) => {
  const { isIOS, isIOS9 } = judgePhoneType()
  const win: any = window
  if (apy >= 10000) {
    apy = parseInt(apy + '')
  }
  const param = { apy: apy + '%', phase }
  if (win.flutter_inappwebview) {
    win?.flutter_inappwebview.callHandler('shareImage', param).then(function(result: any) {
      console.info(apy)
    })
  } else {
    if (isIOS || isIOS9) {
      win.webkit?.messageHandlers.shareImage.postMessage(JSON.stringify(param))
    }
  }
}
// get device ratio
export const getPixelRatio = function(context: any) {
  const backingStore =
    context.backingStorePixelRatio ||
    context.webkitBackingStorePixelRatio ||
    context.mozBackingStorePixelRatio ||
    context.msBackingStorePixelRatio ||
    context.oBackingStorePixelRatio ||
    context.backingStorePixelRatio ||
    1
  return (window.devicePixelRatio || 1) / backingStore
}

export const usePoolDatas = () => {
  const { account } = useActiveWeb3React()
  const [getAPYData, setAPYData] = useState<any>(null)
  const { numberAuto } = useEarnState()
  useEffect(() => {
    const getDataFun = async () => {
      const userAddress = account && account !== 'null' ? '?userAddress=' + account : ''
      try {
        const { data } = await axios.get(REACT_APP_POOL_URL + userAddress)
        const keysDataAddress = Object.keys(data)
        const newItem: any = {}
        keysDataAddress.map((item: any) => {
          newItem[item] = ''
          const wrapSecond: any = {}
          const itemKeys = Object.keys(data[item])
          itemKeys.map((keyds: any) => {
            const arr = []
            if (data[item][keyds][0]) {
              arr[0] = BigNumber.from(data[item][keyds][0])
            } else {
              arr[0] = BigNumber.from(data[item][keyds])
            }
            const result = {
              result: arr
            }
            wrapSecond[keyds] = result
          })
          newItem[item] = wrapSecond
          // console.info(newItem,'newItemnewItem')
          return newItem
        })

        setAPYData(newItem)
      } catch (error) {
        console.info(error)
        setAPYData(undefined)
      }
    }
    getDataFun()
  }, [account, numberAuto])
  return getAPYData
}
// get APY data

interface ApyData {
  stakingRewardAddress: string;
  // 这里可以根据实际情况添加更多字段
  [key: string]: any; // 如果有更多未知的字段，可以用索引签名
}

export const GetApyFun = function() {
  const [getAPYData, setAPYData] = useState<Record<string, ApyData>>({})
  useEffect(() => {
    let timer: any
    const getDataFun = async () => {
      try {
        const data = await getAiaSwapCoinApy()
        let obj:Record<string, ApyData> = {}
        data.forEach((item: Record<string, any>)=>{
         const stakingRewardAddress: string = item.stakingRewardAddress.toLocaleLowerCase()
          obj[stakingRewardAddress] = {
            ...item,
            stakingRewardAddress: stakingRewardAddress
          }
        })
        setAPYData(obj)
      } catch (error) {
        setAPYData({})
      }
    }
    getDataFun().then()
    // eslint-disable-next-line prefer-const
    timer = setInterval(() => {
      getDataFun().then()
    }, 60*1000)
    return () => {
      clearInterval(timer)
      setAPYData({})
    }
  }, [])
  return getAPYData
}
// get allPool data
export const GetAllPoll = function() {
  const [allPoll, setAllPoll] = useState<any>([])
  useEffect(() => {
    getSwapPoolList({status: '', type: 'zh-CN'}).then((res)=>{
      const data = getDataHandle(res)
      setAllPoll(data);
    })
    setAllPoll([])
  }, [])
  return allPoll
}

export const TypeFun = (stakingInfo: any, t: any) => {
  const setBngFun = useMemo(
    () => (stakingInfo: any, t: any) => {
      const p = stakingInfo?.type
      switch (p) {
        case 'one':
          return t('Phase 1')
        case 'two':
          return t('Phase 2')
        case 'three':
          return t('Phase 3')
        case 'four':
          return t('Phase 4')
        default:
          if (!p) {
            return ''
          } else {
            return t(p)
          }
      }
    },
    [stakingInfo?.type]
  )
  return setBngFun(stakingInfo, t)
}

export const SetBngFunCommon = (stakingInfo: any) => {
  const reslut = useMemo(
    () => (info: any) => {
      if (info?.active) {
        switch (info?.type) {
          case 'one':
            return { bng: 'linear-gradient(184deg, #FFFFFF  10%, #FFF3CF 100%)', col: '#FFA70E' }
          case 'two':
            return { bng: 'linear-gradient(184deg, #FFFFFF 10%, #DBF6FF 100%)', col: '#26B6E6' }
          case 'three':
            return { bng: 'linear-gradient(184deg, #FFFFFF 10%, #DDF9E6 100%)', col: '#39C465' }
          default:
            // if (info?.type) {
            //     return { bng: 'linear-gradient(184deg, #FFFFFF 10%, #DDF9E6 100%)', col: '#39C465' };
            // }
            return { bng: '#ffffff', col: '#000000' }
        }
      } else {
        return { bng: '#ffffff', col: '#000000' }
      }
    },
    [stakingInfo]
  )
  return reslut(stakingInfo)
}
