import { ActionCreatorWithPayload, createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { TokenList, Version } from '@uniswap/token-lists'
import { getAiaSwapCoinConfig } from '../../services/global'

export const fetchTokenList: Readonly<{
  pending: ActionCreatorWithPayload<{ url: string; requestId: string }>
  fulfilled: ActionCreatorWithPayload<{ url: string; tokenList: TokenList; requestId: string }>
  rejected: ActionCreatorWithPayload<{ url: string; errorMessage: string; requestId: string }>
}> = {
  pending: createAction('lists/fetchTokenList/pending'),
  fulfilled: createAction('lists/fetchTokenList/fulfilled'),
  rejected: createAction('lists/fetchTokenList/rejected')
}
// add and remove from list options
export const addList = createAction<string>('lists/addList')
export const removeList = createAction<string>('lists/removeList')

// select which lists to search across from loaded lists
export const enableList = createAction<string>('lists/enableList')
export const disableList = createAction<string>('lists/disableList')

// versioning
export const acceptListUpdate = createAction<string>('lists/acceptListUpdate')
export const rejectVersionUpdate = createAction<Version>('lists/rejectVersionUpdate')

// 获取 tokenList 列表的异步 action
export const fetchTokenListFromApi = createAsyncThunk(
  'lists/fetchTokenListFromApi',
  async (_, { rejectWithValue }) => {
  try {
    // 调用接口获取 tokens
    return await getAiaSwapCoinConfig() // 假设接口返回的对象中包含 tokens 字段
  } catch (error) {
    return rejectWithValue(error.message)
  }
})
