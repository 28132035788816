import React, { useCallback, useContext, useState } from 'react'
import { AutoColumn } from '../Column'
import styled, { ThemeContext } from 'styled-components'
import CurrencyLogo from '../../components/CurrencyLogo'
import { useWalletModalToggle } from '../../state/application/hooks'
import { CloseIcon, TYPE } from '../../theme'

import { RowBetween, RowFlat } from '../Row'
import {
} from '../../components/earn/styled'
import {
    ButtonPrimary,
    ButtonPrimaryNew
} from '../Button'
import CretStakingModal from '../../components/earn/CretStakingModal'
import { useStakingTime } from '../../state/stake/hooks'
import UnstakingModal from '../../components/earn/UnstakingModal'
import ClaimRewardModal from '../../components/earn/ClaimRewardModal'
import { useTokenBalance } from '../../state/wallet/hooks'
import { useActiveWeb3React } from '../../hooks'
import { CountUp } from 'use-count-up'
import InterrogatGraySvg from '../../assets/svg/interrogatGraySvg.svg'
import usePrevious from '../../hooks/usePrevious'
import { useTranslation } from 'react-i18next'
import { BIG_INT_ZERO, BIG_INT_SECONDS_IN_WEEK } from '../../constants'
import { JSBI } from '@uniswap/sdk'
import { Countdown } from 'pages/Earn/Countdown'
import Modal from '../Modal'


interface CretManageProps {
    isOpen: boolean
    onDismiss: () => void
    stakingInfos?: any
    rewardCoin?: string,
    currencyIdA?: string,
    currencyIdB?: string,
    stakingRewardAddress?: string,
    apy?: number,
    type?: string | null | any
}
export default function CretManageModal({
    isOpen,
    onDismiss,
    apy,
    currencyIdA,
    currencyIdB,
    stakingInfos,
    rewardCoin,
    type
}: CretManageProps) {
    const theme = useContext(ThemeContext)
    const { account } = useActiveWeb3React()

    // get currencies and pair
    const currencyA = stakingInfos?.tokens[0]

    const stakingInfo = stakingInfos

    // detect existing unstaked LP position to show add button if none found
    const userLiquidityUnstaked = useTokenBalance(account ?? undefined, stakingInfo?.stakedAmount?.token)
    //   const showAddLiquidityButton = Boolean(stakingInfo?.stakedAmount?.equalTo('0') && userLiquidityUnstaked?.equalTo('0'))

    // toggle for staking modal and unstaking modal
    const [showStakingModal, setShowStakingModal] = useState(false)
    const [showUnstakingModal, setShowUnstakingModal] = useState(false)
    const [showClaimRewardModal, setShowClaimRewardModal] = useState(false)

    const countUpAmount = stakingInfo?.earnedAmount?.toFixed(6) ?? '0'
    const countUpAmountPrevious = usePrevious(countUpAmount) ?? '0'

    const toggleWalletModal = useWalletModalToggle()

    const handleDepositClick = useCallback(() => {
        if (account) {
            setShowStakingModal(true)
        } else {
            toggleWalletModal()
        }
    }, [account, toggleWalletModal])

    const { t } = useTranslation()
    const isStakingEnd = useStakingTime(stakingInfo?.periodFinish)
    const wrappedOnDismiss = () => onDismiss()
    
    return (
        <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss}>
            <PageWrapper gap="20px" justify="center">
                <PageScroller gap="20px" justify="center">
                    <RowBetween>
                        <TYPE.mediumHeader>
                          {type}
                        </TYPE.mediumHeader>
                        <CloseIcon onClick={wrappedOnDismiss} />
                    </RowBetween>
                    <AutoColumn style={{ width: '100%' }}>
                        <RowFlat>
                            <div style={{ marginRight: '5px' }}>
                                <CurrencyLogo currency={currencyA} size="24px" />
                            </div>
                            <Countdown exactEnd={stakingInfo?.periodFinish} tokens={stakingInfo?.tokens} />
                        </RowFlat>
                    </AutoColumn>

                    <AutoColumnData gap={'10px'}>
                        <RowBetween>
                            <TYPE.body fontWeight={700} color={theme.text7} fontSize={14}>{t('Total deposits')}</TYPE.body>
                            <TYPE.body fontSize={20} fontWeight={700} color={theme.text7} fontFamily={'DIN-Bold, DIN'}>
                                {(stakingInfo && stakingInfo.totalStakedAmount?.toSignificant(10, { groupSeparator: ',' })) ?? '-'}{' '}
                                {currencyA?.symbol}
                            </TYPE.body>
                        </RowBetween>
                        <RowBetween style={{ borderTop: '1px solid #F5F5F5', paddingTop: '10px' }}>
                            <TYPE.body style={{ margin: 0 }} color={theme.text7} fontWeight={700} fontSize={14}>{t('Weekly Output')}</TYPE.body>
                            {/* 单币池 详情 每周产量 */}
                            <TYPE.body fontSize={20} fontWeight={700} color={theme.text7} fontFamily={'DIN-Bold, DIN'}>
                                {stakingInfo?.active
                                    ? stakingInfo?.totalRewardRate
                                        ?.multiply(BIG_INT_SECONDS_IN_WEEK)
                                        ?.toFixed(0, { groupSeparator: ',' }) ?? '-'
                                    : '0'} {rewardCoin}
                                <Text>/ {t('week')}</Text>
                            </TYPE.body>
                        </RowBetween>
                        {(apy || apy === 0) && <RowBetween style={{ borderTop: '1px solid #F5F5F5', paddingTop: '10px' }}>
                            <TYPE.body style={{ margin: 0 }} color={theme.text7} fontWeight={700} fontSize={14}>{t('Annual Percentage Yield')}: </TYPE.body>
                            <TYPE.body fontSize={20} fontWeight={700} color={theme.text7} fontFamily={'DIN-Bold, DIN'}>
                                <Text>{apy}% </Text>
                            </TYPE.body>
                        </RowBetween>}
                    </AutoColumnData>

                    <AutoColumnData gap={'10px'}>
                        <RowBetween>
                            <TYPE.body fontWeight={700} color={theme.text7} fontSize={14}>
                                {t('Staking Amount')}
                            </TYPE.body>
                            <TYPE.body fontSize={20} fontWeight={700} color={theme.text7} fontFamily={'DIN-Bold, DIN'}>
                                {stakingInfo?.stakedAmount?.toSignificant(6) ?? '-'}
                            </TYPE.body>
                        </RowBetween>
                    </AutoColumnData>

                    <AutoColumnData gap={'10px'}>
                        <RowBetween>
                            <TYPE.body fontWeight={700} color={theme.text7} fontSize={14}>
                                {/* 单币池 详情 未赎回 展示 */}
                                {t('Unclaimed')} {rewardCoin}
                            </TYPE.body>
                            <TYPE.body fontSize={20} fontWeight={700} color={theme.text7} fontFamily={'DIN-Bold, DIN'}>
                                <CountUp
                                    key="123"
                                    isCounting
                                    decimalPlaces={4}
                                    start={parseFloat(countUpAmountPrevious)}
                                    end={parseFloat(countUpAmount)}
                                    thousandsSeparator={','}
                                    duration={1}
                                />
                            </TYPE.body>
                        </RowBetween>
                        <RowBetween style={{ borderTop: '1px solid #F5F5F5', paddingTop: '10px' }}>
                            <TYPE.body style={{ margin: 0 }} color={theme.text7} fontWeight={400} fontSize={14}>{stakingInfo?.active
                                ? stakingInfo?.rewardRate
                                    ?.multiply(BIG_INT_SECONDS_IN_WEEK)
                                    ?.toSignificant(4, { groupSeparator: ',' }) ?? '-'
                                : '0'}
                                {` ${rewardCoin} / ${t('week')}`}</TYPE.body>
                            {stakingInfo?.earnedAmount && JSBI.notEqual(BIG_INT_ZERO, stakingInfo?.earnedAmount?.raw) && (
                                <ButtonPrimaryNew onClick={() => setShowClaimRewardModal(true)}>
                                    {t('Claim')}
                                </ButtonPrimaryNew>
                            )}
                        </RowBetween>
                    </AutoColumnData>
                    <AutoColumnData color={theme.text7} gap={'10px'}>
                        <RowFlat style={{ alignItems: 'center' }}>
                            <img width={18} src={InterrogatGraySvg} alt="AIA" />
                            <TYPE.main fontWeight={600} fontSize={13} style={{ marginLeft: '5px' }}>
                                {t('When you withdraw, the contract will automagically claim AIA on your behalf!', {
                                    currency: `${rewardCoin}`
                                })}
                            </TYPE.main>
                        </RowFlat>
                    </AutoColumnData>
                    <PositionInfo gap="lg" justify="center" dim={false}>
                    <DataRow style={{ marginBottom: '1rem', flexDirection: 'row', justifyContent: (stakingInfo.unlock && stakingInfo?.stakedAmount?.greaterThan(JSBI.BigInt(0))) || (stakingInfo && stakingInfo.active && stakingInfo?.stakedAmount?.greaterThan(JSBI.BigInt(0)) && isStakingEnd) ? 'space-between' : 'center' }} >
                            {stakingInfo && stakingInfo.active && (
                                <ButtonBottom padding="8px" borderRadius="8px" width="160px" onClick={handleDepositClick}>
                                    {stakingInfo?.stakedAmount?.greaterThan(JSBI.BigInt(0))
                                        ? t('Deposit')
                                        : t('Deposit') + ` ${currencyA?.symbol} Tokens`}
                                </ButtonBottom>
                            )}

                            {((stakingInfo.unlock && stakingInfo?.stakedAmount?.greaterThan(JSBI.BigInt(0))) || ((stakingInfo?.stakedAmount?.greaterThan(JSBI.BigInt(0))) && isStakingEnd)) && (
                                <>
                                    <ButtonBottom padding="8px" borderRadius="8px" width="160px" onClick={() => setShowUnstakingModal(true)}>
                                        {t('Withdraw')}
                                    </ButtonBottom>
                                </>
                            )}
                        </DataRow>
                    </PositionInfo>
                    {stakingInfo && (
                        <>
                            <CretStakingModal
                                rewardCoin={rewardCoin}
                                isOpen={showStakingModal}
                                onDismiss={() => setShowStakingModal(false)}
                                stakingInfo={stakingInfo}
                                userLiquidityUnstaked={userLiquidityUnstaked}
                            />
                            <UnstakingModal
                                rewardCoin={rewardCoin}
                                isOpen={showUnstakingModal}
                                onDismiss={() => setShowUnstakingModal(false)}
                                stakingInfo={stakingInfo}
                            />
                            <ClaimRewardModal
                                rewardCoin={rewardCoin}
                                isOpen={showClaimRewardModal}
                                onDismiss={() => setShowClaimRewardModal(false)}
                                stakingInfo={stakingInfo}
                            />
                        </>
                    )}
                </PageScroller>
            </PageWrapper>
        </Modal>
    )
}
const PageWrapper = styled(AutoColumn)`
  max-width: 420px;
  width: 100%;
  padding: 1rem;
  background-color: ${({ theme }) => theme.bg9};
`
const PageScroller = styled(AutoColumn)`
  max-height: 80vh;
  overflow-y:auto;
  width: 100%;
`
const PositionInfo = styled(AutoColumn) <{ dim: any }>`
  position: relative;
  max-width: 640px;
  width: 100%;
  opacity: ${({ dim }) => (dim ? 0.6 : 1)};
`

// const BottomSection = styled(AutoColumn)`
//   border-radius: 12px;
//   width: 100%;
//   position: relative;
// `



// const StyledBottomCard = styled(DataCard) <{ dim: any }>`
//   background: ${({ theme }) => theme.bg3};
//   opacity: ${({ dim }) => (dim ? 0.4 : 1)};
//   margin-top: -40px;
//   padding: 0 1.25rem 1rem 1.25rem;
//   padding-top: 32px;
//   z-index: 1;
// `

// const PoolData = styled(DataCard)`
//   background: none;
//   border: 1px solid ${({ theme }) => theme.bg4};
//   padding: 1rem;
//   z-index: 1;
// `

const DataRow = styled(RowBetween)`
  justify-content: center;
  gap: 12px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    gap: 12px;
  `};
`
const AutoColumnData = styled(AutoColumn)`
   background-color: ${({ theme }) => theme.white};
   padding: 10px 20px;
   box-shadow: 0px 5px 2px 1px rgba(14, 37, 74, 0.01);
   border-radius: 15px;
   width: 100%;
`
const Text = styled.span`
    font-size: 15px;
    font-weight: bold;
    color: ${({ theme }) => theme.text7};
`
const ButtonBottom = styled(ButtonPrimary)`
    min-width: 45%;
    width:auto;
    padding:8px 15px;
    border-radius: 12px;
    box-shadow: 0 3px 0 0px #0090ff;
    box-sizing: border-box;
    &:focus {
        box-shadow: 0 3px 0 0px #0090ff;
    }
    &:active {
        box-shadow: 0 3px 0 0px #0090ff;
   }
`