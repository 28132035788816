import React, { useContext, useMemo } from 'react'
import { AutoColumn } from '../../components/Column'
import styled, { ThemeContext } from 'styled-components'
import { TYPE } from '../../theme'
import { RowBetween, RowCenter } from '../../components/Row'
import { DataCardImg } from '../../components/earn/styled'
import { useTranslation } from 'react-i18next'
import EmptyNew from '../../assets/images/emptyNew.png'
import bannerImg from '../../assets/images/banner.png'
import { useStakingInfoPage } from "../../state/stake/newHooks";
import PoolCardCret from "../../components/earn/PoolCardCret";
import PoolCard from "../../components/earn/PoolCard";
import { useDerivedSwapAitdUsdt } from "../../state/swap/hooks";
import { GetApyFun } from "../../components/earn/utils";
import { Spin } from "antd";


export default function Earn() {
  const theme = useContext(ThemeContext)
  const {t} = useTranslation()
  const getUsdt = useDerivedSwapAitdUsdt()
  const apyData = GetApyFun();
  const {getProgress, isActive, setIsActive, onProgressChange, stakingInfoList, loading} = useStakingInfoPage();

  const Empty = useMemo(() => {
    return (
      <EmptyProposalsNew>
        <RowCenter justify="center">
          <img src={EmptyNew} alt="AIA" style={{width: '84px', marginBottom: '0.3rem'}}/>
        </RowCenter>
        <TYPE.body color={theme.glay} textAlign="center" fontSize={14}>
          {t('No Active Pools')}
        </TYPE.body>
      </EmptyProposalsNew>
    )
  }, [ t, theme.glay ])

  const listInfo = useMemo(()=>{
    if(!isActive) {
      // 已参与的
      return stakingInfoList.filter((stakingInfo)=>{
        return Boolean(stakingInfo?.stakedAmount.greaterThan('0'))
      })
    }
    return stakingInfoList
  }, [stakingInfoList, isActive])

  return (
    <PageWrapper gap="lg" justify="center">
      <TopSection gap="md">
        <DataCardImg>
          <img width="100%" src={bannerImg} alt="AIA"></img>
        </DataCardImg>
      </TopSection>

      <AutoColumn gap="lg" style={{width: '100%', maxWidth: '720px'}}>
        {/* {false && <CanvasImgShare />} */}
        <RowBetween>
          <SwitchTypeLeft>
            <TYPE.mediumHeader className={getProgress === 0 ? 'active' : ''} onClick={() => onProgressChange(0)}>
              {t('In Progress')}
            </TYPE.mediumHeader>
            <TYPE.mediumHeader className={getProgress !== 0 ? 'active' : ''} onClick={() => onProgressChange(1)}>
              {t('Ended')}
            </TYPE.mediumHeader>
          </SwitchTypeLeft>
          <SwitchType>
            <SwitchTypeSpan isActive={isActive} onClick={() => setIsActive(true)}>
              {t('ALL')}
            </SwitchTypeSpan>
            <SwitchTypeSpan isActive={!isActive} onClick={() => setIsActive(false)}>
              {t('Participated')}
            </SwitchTypeSpan>
          </SwitchType>
        </RowBetween>
        <PoolSection>
          {
            loading ? (<div style={{height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Spin size="large" />
              </div>)
              : listInfo.length > 0
                ? (
                  listInfo.map((stakingInfo, keys)=>{
                    const keyComponent = stakingInfo?.stakingRewardAddress + keys
                    const stakingRewardAddress = stakingInfo?.stakingRewardAddress.toLocaleLowerCase()
                    const {apr, apy} = apyData[stakingRewardAddress] ? apyData[stakingRewardAddress] : {apr: undefined, apy: undefined}
                    const getCardComponetFun = (PoolCardComponent: any, argObject: Record<string, any>) => {
                      return <PoolCardComponent {...argObject} key={keyComponent} stakingInfo={stakingInfo} />
                    }
                    // @ts-ignore
                    if (stakingInfo.tokens.length === 1) {
                      return getProgress === 0 ? getCardComponetFun(PoolCardCret, {
                        apy: apy,
                        apr: apr
                      }) : getCardComponetFun(PoolCardCret, {isEnd: true})
                    }
                    return getProgress === 0? getCardComponetFun(PoolCard, { apy: apy, apr: apr, USDTNUM: getUsdt }) : getCardComponetFun(PoolCard, { isEnd: true, USDTNUM: getUsdt })
                  })
                )
                : (Empty)
          }
        </PoolSection>
      </AutoColumn>
    </PageWrapper>
  )
}
const PageWrapper = styled(AutoColumn)`
    max-width: 640px;
    width: 100%;
`

const TopSection = styled(AutoColumn)`
    max-width: 720px;
    width: 100%;
`

const PoolSection = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 10px;
    row-gap: 15px;
    width: 100%;
    justify-self: center;
`

// const DataRow = styled(RowBetween)`
//   ${({ theme }) => theme.mediaWidth.upToSmall`
//     flex-direction: column;
// `};
// `
const SwitchType = styled(RowBetween)`
    background-color: ${({theme}) => theme.white};
    color: ${({theme}) => theme.glay};
    border-radius: 22px;
    align-items: center;
    width: auto;
    cursor: pointer;
`
const SwitchTypeLeft = styled(RowBetween)`
    border-radius: 22px;
    align-items: center;
    width: auto;
    cursor: pointer;

    div {
        margin-right: 1rem;
        color: ${({theme}) => theme.glay};
        font-weight: 600;
        font-size: 16px;
    }

    .active {
        position: relative;
        color: ${({theme}) => theme.text7};

        &::after {
            content: '';
            position: absolute;
            width: 45%;
            height: 3px;
            background-color: ${({theme}) => theme.text7};
            transform: translateX(-50%);
            left: 50%;
            bottom: -7px;
            border-radius: 2px;
        }
    }
`

const SwitchTypeSpan = styled.span<{ isActive: boolean }>`
    display: inline-block;
    background-color: ${({theme, isActive}) => (isActive ? theme.text8 : theme.white)};
    color: ${({theme, isActive}) => (isActive ? theme.white : '#CCCCCC')};
    font-size: 13px;
    border-radius: 22px;
    padding: 2px 25px;
`
const EmptyProposalsNew = styled(AutoColumn)`
    padding: 3rem 12px;
    border-radius: 15px;
    background: #ffffff;
    box-shadow: 0px 2px 1px 1px rgba(14, 37, 74, 0.1);
`
