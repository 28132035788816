import React, { useContext, useMemo, useState } from 'react'
import { AutoColumn } from '../Column'
import { RowBetween } from '../Row'
import styled, { ThemeContext } from 'styled-components'
import {
  TYPE,
} from '../../theme'
import { ButtonPrimaryBlack, ButtonPrimaryNew } from '../Button'
import {
  Break
} from './styled'
import { BIG_INT_SECONDS_IN_WEEK } from '../../constants'
import { useTranslation } from 'react-i18next'
import CurrencyLogo from '../CurrencyLogo'
import CretManageModal from './CretManageModal'
import { isMobile } from 'react-device-detect'
import LinkSvg from '../../assets/svg/link.svg'
import { handleShareClick, SetBngFunCommon } from './utils'
import { QuestionHelpers } from 'components/QuestionHelper'
const StatContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 1rem;
  margin-right: 1rem;
  margin-left: 1rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  display: none;
`};
`

const Wrapper = styled(AutoColumn) <{ showBackground: boolean; bgColor: any }>`
  border-radius: 12px;
  width: 100%;
  overflow: hidden;
  position: relative;
  background: ${({ theme, bgColor = '#ffffff', showBackground }) => bgColor};
  color: ${({ theme }) => (theme.text7)} !important;
  box-shadow: 0px 2px 0px 1px rgba(14, 37, 74, 0.1);
`

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  z-index: 1;

  /* ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: 48px 1fr 96px;
  `}; */
`
const ColumnSection = styled.div`
    display: flex;
  flex-direction: column;
  width: auto;
`
const BottomSection = styled.div<{ showBackground: boolean }>`
  padding: 12px 16px;
  opacity: ${({ showBackground }) => (showBackground ? '1' : '0.4')};
  border-radius: 0 0 12px 12px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  z-index: 1;
`
const MobileContainer = styled.div` 
  gap: 12px;
  margin-right: 20px;
  margin-left: 20px;
  padding-bottom: 5px;
`

interface PoolCardCretArg {
  isEnd?: boolean;
  stakingInfo: any;
  apy?: number;
  apr?: number;
}
export default function PoolCardCret({ stakingInfo, isEnd = false, apy, apr }: PoolCardCretArg) {
  const currency0 = stakingInfo.tokens[0]
  const theme = useContext(ThemeContext)



  const { t } = useTranslation()
  const isStaking = Boolean(stakingInfo.stakedAmount.greaterThan('0'))
  const [showManageRewardModal, setShowManageRewardModal] = useState(false)
  const rewardCoin = useMemo(() => {
    const coin = currency0?.symbol
    const isIncludeCAITD = coin.indexOf('AIA') > -1 || coin.indexOf('YOHO') > -1
    return stakingInfo.reward ? stakingInfo.reward == 'WAIA' ? 'AIA' : stakingInfo.reward : isIncludeCAITD ? 'AIA' : coin
  }, [currency0?.symbol])


  const setBngFun = SetBngFunCommon(stakingInfo)

  return (
    <Wrapper showBackground={isStaking} bgColor={setBngFun.bng}>
      <TopSection style={{ paddingBottom: '20px' }}>
        <ColumnSection>
          <TYPE.white fontWeight={600} fontSize={18} color={stakingInfo?.active ? theme.text7 : theme.text11}>
            {stakingInfo.type}
          </TYPE.white>
          <div style={{ "opacity": stakingInfo?.active ? 1 : 0.6 }}>
            <CurrencyLogo currency={currency0} size="20px" />
          </div>
        </ColumnSection>
        {
          !isEnd ? <ButtonPrimaryNew padding="8px" borderRadius="13px" onClick={() => setShowManageRewardModal(true)}>
            {isStaking ? t('Manage') : t('Deposit')}
          </ButtonPrimaryNew> : <ButtonPrimaryBlack padding="8px" borderRadius="13px" onClick={() => setShowManageRewardModal(true)}>
            {isStaking ? t('Manage') : t('Details')}
          </ButtonPrimaryBlack>
        }
      </TopSection>

      {
        (!isEnd && !!apy && apy >= 0) && <MobileContainer>
          <RowBetween>
            <TYPE.white color={'#000000'} fontSize={28}>
              <label style={{ fontFamily: 'DIN-Bold, DIN' }}>{apy}% </label>
              <label style={{ fontSize: '12px', fontWeight: 400 }}>APY</label>
              <QuestionHelpers
                apr={`${apr}%`}
                props={{ padding: 0 }}
                text={t('The LP transaction')}
              />
            </TYPE.white>
            {isMobile && <img src={LinkSvg} width={13} onClick={() => { handleShareClick(apy || 0, currency0.symbol) }} />}
          </RowBetween>
        </MobileContainer>
      }

      <StatContainer>
        <RowBetween>
          <TYPE.white color={theme.text7} fontWeight={400}> {t('Liquidity')}</TYPE.white>
          <TYPE.white color={theme.text7} fontWeight={400}>
            {(stakingInfo && stakingInfo.totalStakedAmount?.toSignificant(10, { groupSeparator: ',' })) ?? '-'}{' '}
            {currency0.symbol}
          </TYPE.white>
        </RowBetween>
        <RowBetween>
          {/* 列表 每周产量 */}
          <TYPE.white color={theme.text7} fontWeight={400}> {t('Weekly Output')} </TYPE.white>
          <TYPE.white color={theme.text7} fontWeight={400}>
            {stakingInfo
              ? stakingInfo.active
                ? `${stakingInfo.totalRewardRate
                  ?.multiply(BIG_INT_SECONDS_IN_WEEK)
                  ?.toFixed(0, { groupSeparator: ',' })} ${rewardCoin} / ${t('week')}`
                : `0 ${rewardCoin} / ${t('week')}`
              : '-'}
          </TYPE.white>
        </RowBetween>
      </StatContainer>
      {
        isStaking && (
          <>
            <Break />
            <BottomSection showBackground={true}>
              {/* 列表 收益率 */}
              <TYPE.black fontWeight={500} color={theme.text7}>
                <span>{t('Your rate')}</span>
              </TYPE.black>

              <TYPE.black style={{ textAlign: 'right' }} color={theme.text7} fontWeight={500}>
                {/* <span role="img" aria-label="wizard-icon" style={{ marginRight: '0.5rem' }}>
                ⚡
              </span> */}
                {stakingInfo
                  ? stakingInfo.active
                    ? `${stakingInfo.rewardRate
                      ?.multiply(BIG_INT_SECONDS_IN_WEEK)
                      ?.toSignificant(4, { groupSeparator: ',' })} ${rewardCoin} / ${t('week')}`
                    : `0 ${rewardCoin} / ${t('week')}`
                  : '-'}
              </TYPE.black>
            </BottomSection>
          </>
        )
      }

      <CretManageModal
        apy={!isEnd ? apy : undefined}
        isOpen={showManageRewardModal}
        onDismiss={() => setShowManageRewardModal(false)}
        stakingInfos={stakingInfo}
        rewardCoin={rewardCoin}
        type={stakingInfo.type}
      />
    </Wrapper>
  )
}
