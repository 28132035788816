import { Currency, Pair } from '@uniswap/sdk'
import React, { useState, useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { darken } from 'polished'
import { useCurrencyBalance } from '../../state/wallet/hooks'
import CurrencySearchModal from '../SearchModal/CurrencySearchModal'
import CurrencyLogo from '../CurrencyLogo'
import DoubleCurrencyLogo from '../DoubleLogo'
import { RowBetween } from '../Row'
import { TYPE } from '../../theme'
import { Input as NumericalInput } from '../NumericalInput'
import { ReactComponent as DropDown } from '../../assets/images/dropdown.svg'

import { useActiveWeb3React } from '../../hooks'
import { useTranslation } from 'react-i18next'
import useTheme from '../../hooks/useTheme'
// import { toFixed } from 'utils/bigNumber'

const InputRow = styled.div<{ selected: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  padding: ${({ selected }) => (selected ? '0.75rem 0.5rem 0.75rem 1rem' : '0.75rem 0.75rem 0.75rem 1rem')};
  background-color: ${({ theme }) => theme.bg6};
  border-radius: 15px;
  input{
    background-color: ${({ theme }) => theme.bg6};
  }
`

const CurrencySelect = styled.button<{ selected: boolean }>`
  align-items: center;
  height: 2.2rem;
  font-size: 20px;
  font-weight: 500;
  background-color: ${({ selected, theme }) => (selected ? theme.bg6 : theme.bg6)};
  color: ${({ selected, theme }) => (selected ? theme.text1 : '#333333')};
  border-radius: 12px;
  /* box-shadow: ${({ selected }) => (selected ? 'none' : '0px 6px 10px rgba(0, 0, 0, 0.075)')}; */
  outline: none;
  cursor: pointer;
  user-select: none;
  border: none;
  padding: 0 0.5rem;

  :focus,
  :hover {
    background-color: ${({ selected, theme }) => (selected ? theme.bg2 : theme.bg6)};
  }
`

const LabelRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  color: ${({ theme }) => theme.text1};
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.75rem 1rem 0 1rem;
  span:hover {
    cursor: pointer;
    color: ${({ theme }) => darken(0.2, theme.text2)};
  }
`

const Aligner = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const StyledDropDown = styled(DropDown) <{ selected: boolean }>`
  margin: 0 0.25rem 0 0.20rem;
  height: 30%;

  path {
    stroke: ${({ selected, theme }) => (selected ? theme.text1 : theme.text1)};
    stroke-width: 1.5px;
  }
`

const InputPanel = styled.div<{ hideInput?: boolean }>`
  ${({ theme }) => theme.flexColumnNoWrap}
  position: relative;
  border-radius: ${({ hideInput }) => (hideInput ? '8px' : '20px')};
  background-color: ${({ theme }) => theme.bg2};
  z-index: 1;
`

const Container = styled.div<{ hideInput: boolean }>`
  border-radius: ${({ hideInput }) => (hideInput ? '8px' : '20px')};
  /* border: 1px solid ${({ theme }) => theme.bg2}; */
  background-color: ${({ theme }) => theme.bg1};
  
`

const StyledTokenName = styled.span<{ active?: boolean }>`
  ${({ active }) => (active ? '  margin: 0 0.25rem 0 0.25rem;' : '  margin: 0 0.25rem 0 0.25rem;')}
  font-size:  ${({ active }) => (active ? '16px' : '16px')};
  color: ${({ theme }) => theme.text6};
  font-weight: bold;
`

const StyledBalanceMax = styled.button`
  height: 28px;
  /* background-color: ${({ theme }) => theme.primary5}; */
  border: 1px solid ${({ theme }) => theme.primaryText1};
  border-radius: 0.75rem;
  font-size: 0.575rem;
  font-weight: 500;
  cursor: pointer;
  margin-right: 0.5rem;
  padding: 0 0.6rem;
  color: ${({ theme }) => theme.primaryText1};
  :hover {
    border: 1px solid ${({ theme }) => theme.primary1};
  }
  :focus {
    border: 1px solid ${({ theme }) => theme.primary1};
    outline: none;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin-right: 0.5rem;
  `};
`

interface CurrencyInputPanelProps {
    value: string
    onUserInput: (value: string) => void
    onMax?: () => void
    showMaxButton: boolean
    label?: string
    onCurrencySelect?: (currency: Currency) => void
    currency?: Currency | null
    disableCurrencySelect?: boolean
    hideBalance?: boolean
    pair?: Pair | null
    hideInput?: boolean
    otherCurrency?: Currency | null
    id: string
    showCommonBases?: boolean
    customBalanceText?: string
    errorTip?: boolean
    showFocus?: boolean
    isDisabled?: boolean
    sx?:any
    filterCurrency?: Record<string, any>[]
}

export default function CurrencyInputPanel({
    value,
    onUserInput,
    onMax,
    showMaxButton,
    label = 'Input',
    onCurrencySelect,
    currency,
    disableCurrencySelect = false,
    hideBalance = false,
    pair = null, // used for double token logo
    hideInput = false,
    otherCurrency,
    id,
    showCommonBases,
    customBalanceText,
    errorTip = false,
    showFocus = false,
    isDisabled = false,
    sx,
    filterCurrency
}: CurrencyInputPanelProps) {
    const { t } = useTranslation()
    const [isFocus, setIsFocus] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const { account } = useActiveWeb3React()
    const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)
    const theme = useTheme()

    const handleDismissSearch = useCallback(() => {
        setModalOpen(false)
    }, [setModalOpen])

    const isRed = useMemo(() => {
        return showFocus && selectedCurrencyBalance && Number(value || 0) > Number(selectedCurrencyBalance?.toExact() || 0)
    }, [isFocus, selectedCurrencyBalance, value, showFocus])
    return (
        <InputPanel id={id}>
            <Container hideInput={hideInput}>
                <InputRow
                style={hideInput ? { padding: '0', borderRadius: '8px',...sx } : {
                    ...sx,
                    boxShadow: isFocus ? `0 0 0 1px ${isRed ? '#EB2727' : '#2750EB'}` : (isRed ? '0 0 0 1px #EB2727' : '')
                }} selected={disableCurrencySelect}>
                    {!hideInput && (
                        <>
                            <NumericalInput
                                disabled={isDisabled}
                                style={{ color: theme.text7, cursor: isDisabled ? 'no-drop' : 'auto',...sx}}
                                onFocus={() => setIsFocus(true)}
                                onBlur={() => setIsFocus(false)}
                                className="token-amount-input"
                                // value={toFixed(value, 8)}
                                value={value}
                                onUserInput={val => {
                                    onUserInput(val)
                                }}
                            />
                            {account && currency && showMaxButton && label !== 'To' && (
                                <StyledBalanceMax onClick={onMax}>{t('MAX') || 'MAX'}</StyledBalanceMax>
                            )}
                        </>
                    )}
                    {/* t('MAX') ||  */}
                    <CurrencySelect
                        selected={!!currency}
                        className="open-currency-select-button"
                        onClick={() => {
                            if (!disableCurrencySelect) {
                                setModalOpen(true)
                            }
                        }}
                    >
                        <Aligner>
                            {pair ? (
                                <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={24} margin={true} />
                            ) : currency ? (
                                <CurrencyLogo currency={currency} size={'24px'} />
                            ) : null}
                            {pair ? (
                                <StyledTokenName className="pair-name-container">
                                    {pair?.token0.symbol}:{pair?.token1.symbol}
                                </StyledTokenName>
                            ) : (
                                <StyledTokenName className="token-symbol-container" active={Boolean(currency && currency.symbol)}>
                                    {(currency && currency.symbol && currency.symbol.length > 20
                                        ? currency.symbol.slice(0, 4) +
                                        '...' +
                                        currency.symbol.slice(currency.symbol.length - 5, currency.symbol.length)
                                        : currency?.symbol) || t('selectToken')}
                                </StyledTokenName>
                            )}
                            {!disableCurrencySelect && <StyledDropDown selected={!!currency} />}
                        </Aligner>
                    </CurrencySelect>
                </InputRow>
                {!hideInput && (
                    <LabelRow>
                        <RowBetween>
                            <TYPE.body color={theme.text2} fontWeight={500} fontSize={14}>
                                {/* {label} */}
                            </TYPE.body>
                            {account && (
                                <TYPE.body
                                    fontFamily={"PingFang SC-Bold, PingFang SC"}
                                    onClick={onMax}
                                    color={theme.text10}
                                    fontWeight={700}
                                    fontSize={13}
                                    style={{ display: 'inline', cursor: 'pointer' }}
                                >
                                    {!hideBalance && !!currency && selectedCurrencyBalance
                                        ? (customBalanceText ?? t('Balance')) + ': ' + selectedCurrencyBalance?.toSignificant(8)
                                        : ' -'}
                                </TYPE.body>
                            )}
                        </RowBetween>
                    </LabelRow>
                )}
            </Container>

            {!disableCurrencySelect && onCurrencySelect && (
                <CurrencySearchModal
                    isOpen={modalOpen}
                    onDismiss={handleDismissSearch}
                    onCurrencySelect={onCurrencySelect}
                    selectedCurrency={currency}
                    otherSelectedCurrency={otherCurrency}
                    showCommonBases={showCommonBases}
                    filterCurrency={filterCurrency}
                />
            )}
        </InputPanel>
    )
}
